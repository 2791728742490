<template>
    <svg
        class="stroke-current"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 13C13.3137 13 16 10.3137 16 7C16 3.68629 13.3137 1 10 1C6.68629 1 4 3.68629 4 7C4 10.3137 6.68629 13 10 13Z"
            stroke-width="1.5"
            stroke-miterlimit="10"
        />
        <path
            d="M0.90625 18.2499C1.82775 16.6534 3.15328 15.3277 4.74958 14.406C6.34588 13.4843 8.1567 12.999 10 12.999C11.8433 12.999 13.6541 13.4843 15.2504 14.406C16.8467 15.3277 18.1722 16.6534 19.0938 18.2499"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
